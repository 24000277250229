.start-building {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("/assets/contact_form_bg_mobile.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 0 48px;
    position: relative;

    @include is-pc {
        background-image: url("/assets/contact_form_bg.jpg");
        padding: 8rem 0 3rem;
    }

    &__header {
        font-size: 36px;
        font-weight: normal;
        color: $navy-blue-text;
        margin: 12px 0 7px;

        @include is-pc {
            font-size: 7.57rem;
            margin-top: 0;
        }
    }

    &__subheader {
        font-size: 20px;
        color: $green-text;

        @include is-pc {
            font-size: 2.9rem;
            margin: 0 0 2.5%;
        }
    }

    &__family {
        width: 100%;

        @include is-pc {
            position: absolute;
            top: 8rem;
            right: 0;
            z-index: 1;
            width: calc((100% - 48.4rem) / 2 + 3.6rem);
            height: auto;
        }
    }

    &__man {
        width: 100%;
        z-index: 1;

        @include is-pc {
            position: absolute;
            top: 13rem;
            right: 0;
            width: calc((100% - 48.4rem)/2 + 5.6rem);
            height: auto;
        }

        &.on-mobile {
            ~ .start-building__header {
                margin: -15px 0 7px;
            }
        }
    }

    &__contractor {
        width: 100%;
        z-index: 1;

        @include is-pc {
            position: absolute;
            top: 7rem;
            right: 0;
            width: calc((100% - 48.4rem)/2 + 1.6rem);
            height: auto;
        }

        &.on-mobile {
            ~ .start-building__header {
                margin: -10px 0 7px;
            }
        }
    }

    .form {
        width: calc(100% - 34px);
        padding: 31px 25px;
        margin: 21px 0;
        border-radius: 14px;
        background-color: #FFF;
        box-shadow: 0px 23px 36px rgb(0 0 0 / 15%);
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: fade-in 0.2s ease;

        @include is-pc {
            width: 48.4rem;
            padding: 2.7rem;
            margin: 1% 0 6.5%;
            border-radius: 17px;
        }

        &__header {
            font-size: 14px;
            color: $navy-blue-text;
            text-align: center;
            margin-bottom: 13px;

            @include is-pc {
                font-size: 1.72rem;
                margin-bottom: 2.5rem;
            }
        }

        &__group {
            width: 100%;
            display: flex;
            flex-direction: column;

            &-label {
                display: block;
                font-size: 10px;
                margin-bottom: 9px;
                font-weight: 600;
                text-align: center;

                @include is-pc {
                    font-size: 1rem;
                    margin-bottom: .6rem;
                }
            }

            &-input {
                font-family: inherit;
                width: 100%;
                min-height: 39px;
                padding: 9px 15px;
                border-radius: 6px;
                border: 1px solid #C1C1C1;
                font-size: 10px;
                color: $navy-blue-text;
                outline: none;
                transition: border-color 0.15s ease-in;

                @include is-pc {
                    width: 30rem;
                    min-height: 4.25rem;
                    font-size: 1rem;
                    padding: 1rem 1.7rem;
                    margin: 0 auto .5rem;
                }
            }
        }

        &__button {
            @include button;
            background-color: $navy-blue-text;
            margin: 20px auto 0;
            min-height: 35px;
            border: none;
            border-radius: 6px;
            padding: 0.5rem 1.15rem;
            color: white;

            @include is-pc {
                margin: 2.3rem auto 0;
                min-height: 3.29rem;
            }

            &:hover {
                background-color: #3060C1;
            }
        }
    }

    &__contact {
        &-text {
            font-size: 36px;
            color: $navy-blue-text;
            text-align: center;
            margin: 28px 0 23px;

            @include is-pc {
                font-size: 2.9rem;
                margin: 0 0 5.3rem;
            }
        }

        &-row {
            display: flex;
            align-items: center;
            flex-direction: column;

            @include is-pc {
                flex-direction: row;
                margin: 0 0 5rem;
            }
        }

        &-tel {
            display: flex;
            align-items: center;
            margin: 0 0 10px;

            @include is-pc {
                margin: 0 6rem 0 0;
            }

            img {
                width: 29px;
                height: 29px;
                margin: 0 8px;

                @include is-pc {
                    width: 4rem;
                    height: 4rem;
                    margin: 0 1.5rem;
                }
            }

            a {
                font-size: 20px;
                color: $navy-blue-text;
                text-decoration: none;
                transition: color .2s ease;

                @include is-pc {
                    font-size: 4rem;
                }

                &:hover {
                    color: $green-text;
                }

                &:visited {
                    color: $navy-blue-text;
                }
            }
        }

        &-email {
            display: flex;
            align-items: center;

            img {
                width: 29px;
                height: 29px;
                margin: 0 8px;

                @include is-pc {
                    width: 4rem;
                    height: 4rem;
                    margin: 0 1.5rem;
                }
            }

            a {
                font-size: 20px;
                color: $navy-blue-text;
                text-decoration: none;
                transition: color .2s ease;

                @include is-pc {
                    font-size: 4rem;
                }

                &:hover {
                    color: $green-text;
                }

                &:visited {
                    color: $navy-blue-text;
                }
            }
        }
    }
}