@import 'mixins.scss';
@import 'vars.scss';

.reconstructing {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: relative;

    .top-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 84.5vh;
        background-image: url("/assets/bg.svg");
        background-repeat: no-repeat;
        background-position: -4px 3px;
        background-attachment: fixed;
        background-size: 100vw;
    }

    .logo {
        img {
            width: 16.9rem;
            height: auto;
            margin-top: 0.8rem;
        }
    }

    .title {
        font-size: 4.36rem;
        font-weight: bold;
        margin-top: 7.5rem;
        line-height: 4.5rem;
    }

    .subtitle {
        font-size: 1.29rem;
        font-weight: 600;
        margin: 0.8rem 0 2.9rem;
    }

    .form {
        width: 38.4rem;
        height: 6.6rem;
        border-radius: 9px;
        box-shadow: 0px 23px 36px #00000026;
        position: relative;
        padding: 1.1rem .9rem .8rem;

        &__input {
            @include input;
            width: 100%;
            height: 100%;
            padding: 1.2rem 11.6rem 1.1rem 1.7rem;
        }

        &__button {
            @include button;
            position: absolute;
            top: 50%;
            transform: translateY(-44%);
            right: 1.7rem;
            width: 9.5rem;
            height: 3.3rem;
            box-shadow: 0px 4px 23px #1E428A1F;
            background-color: #1E428A;
            color: white;
            font-weight: 500;
            border: none;

            &:hover {
                background-color: #3060C1;
            }
        }
    }

    .join-container {
        display: flex;
        align-items: center;
        margin-top: .2rem;

        &__text {
            font-size: 1.64rem;
            font-weight: 600;
        }

        &__button {
            @include button;
            margin-left: 1.8rem;
            border: 1px solid #C1C1C1;
            box-shadow: 0px 4px 23px #0000001F;
            color: #333333;
            font-weight: 500;
            padding: 1rem;
            background-color: white;

            img {
                filter: invert(.7);
            }

            &:hover {
                color: $navy-blue-text;
            }
        }
    }
}

.side-modal {
    width: 34.4rem;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 11px #00000029;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 2.2rem;
    overflow: auto;
    opacity: 0;
    pointer-events: none;
    transition: opacity .12s ease-in-out;

    &__close {
        position: absolute;
        width: .85rem;
        height: .85rem;
        cursor: pointer;
        top: 2rem;
        right: 2rem;
        transition: filter .15s linear;

        &:hover {
            filter: brightness(.5);
        }
    }

    &__graphic {
        width: 27.5rem;
        height: auto;
        min-height: 187px;
        max-height: 26vh;
        margin-left: 1rem;
    }

    &__header {
        font-size: 1.64rem;
        font-weight: 600;
        text-align: center;
        width: 100%;
        margin: 1.7rem 0 3rem;
    }

    .field {
        width: 100%;
        margin-bottom: 2.8rem;

        &__label {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 0.7rem;
        }

        &__input {
            @include input;
            width: 100%;
            padding: 1rem 1.3rem;
        }

        .checkboxes {
            margin: 1.1rem 0 2.8rem;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__button {
        @include button;
        min-width: 10.2rem;
        box-shadow: 0px 4px 23px #1E428A1F;
        background-color: #1E428A;
        color: white;
        font-size: 1.22rem;
        font-weight: 500;
        border: none;
        margin-left: auto;

        &:hover {
            background-color: #3060C1;
        }

        .side-modal__button img {
            margin-left: 1.5rem;
        }
    }

    &[data-state="active"] {
        opacity: 1;
        pointer-events: initial;
    }
}

#message-box {
    min-width: 45.4rem;
    width: auto;
    max-width: 60vw;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 6px 16px 2px rgb(0 0 0 / 12%);
    position: fixed;
    padding: 1.5rem;
    top: 1rem;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -12rem);
    font-size: 1rem;

    &[data-state="active"] {
        animation: alert-fade-in 0.5s;
        transform: translate(-50%, 0);
    }

    &[data-type="success"] {
        border-left: 5px solid #05D94E;
        background-color: rgb(218 250 229);
    }

    &[data-type="error"] {
        border-left: 5px solid #ec5469;
        background-color: #f5d8dc;
    }
}