@import 'mixins.scss';
@import 'vars.scss';

.contact {
    .header {
        background-position: top;
    }

    &__header {
        background-image: url("/assets/subpage_top_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: 45px;

        @include is-pc {
            padding: 5rem 0 0;
        }
    }

    &__top {
        text-align: center;
        padding: 60px 0 23px;

        @include is-pc {
            padding: 6.5rem 0 4rem;
        }

        &-h1 {
            font-size: 36px;
            color: $navy-blue-text;
            font-weight: normal;

            @include is-pc {
                font-size: 7.46rem;
            }
        }

        &-text {
            font-size: 14px;
            color: $navy-blue-text;
            margin-top: .5rem;

            @include is-pc {
                font-size: 1.98rem;
            }
        }
    }

    .main-info {
        margin: 26px 19px;

        @include is-pc {
            width: 77%;
            display: flex;
            margin: 7rem auto 0;
        }

        &__left {
            width: 100%;

            @include is-pc {
                width: 57%;
            }
        }

        &__right {
            width: 100%;

            @include is-pc {
                width: 43%;
            }

            img {
                width: 100%;
                max-height: 100%;
            }
        }

        &__header {
            font-size: 20px;
            color: $navy-blue-text;
            text-align: center;

            @include is-pc {
                font-size: 2.86rem;
                margin-left: .5rem;
                text-align: left;
            }

            &-green {
                color: $green-text;
            }
        }

        &__text {
            font-size: 14px;
            line-height: 24px;
            color: $brighter-main-text-color;
            margin: 4px 0 43px;
            text-align: center;

            @include is-pc {
                font-size: 1.36rem;
                line-height: 1.6rem;
                width: 85%;
                margin: 0.4rem 0 4.5rem;
                text-align: left;
            }
        }

        &__tel,
        &__mail,
        &__address {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 29px;

            @include is-pc {
                margin-bottom: 1.8rem;
                justify-content: initial;
            }

            img {
                width: 29px;
                height: 29px;
                margin: 0 8px;

                @include is-pc {
                    width: 4rem;
                    height: 4rem;
                    margin: 0 1.7rem;
                }
            }

            a {
                font-size: 24px;
                font-weight: 500;
                color: $navy-blue-text;
                text-decoration: none;
                transition: color .2s ease;
                padding-top: 5px;
                direction: ltr;

                &:hover {
                    color: $green-text;
                }

                @include is-pc {
                    font-size: 2.86rem;
                    padding-top: .6rem;
                }
            }
        }

        &__address {
            margin-top: 32px;
            font-size: 14px;
            line-height: 24px;
            color: $brighter-main-text-color;
            align-items: flex-start;

            img {
                position: relative;
                top: -5px;

                @include is-pc {
                    position: static;
                }
            }

            @include is-pc {
                margin-top: 4rem;
                font-size: 1.36rem;
                line-height: 2.14rem;
            }

            &-header {
                font-size: 24px;
                font-weight: 500;
                color: $navy-blue-text;
                margin: 0 0 14px 0;

                @include is-pc {
                    font-size: 2.86rem;
                    margin: 1.5rem 0 1rem;
                }
            }

            &-text {
                text-align: center;
                margin-left: -39px;

                @include is-pc {
                    text-align: left;
                    margin-left: 0;
                }
            }

            a {
                display: none;
                width: fit-content;
                width: -moz-fit-content;
                margin-top: 1.8rem;
                transition: transform .2s ease-in;
                padding-top: 0;

                @include is-pc {
                    display: flex;
                    padding-top: 0;
                }

                &:hover {
                    transform: translateY(-.3rem);
                }

                img {
                    width: 3rem;
                    height: auto;
                    margin: 0;
                }
            }

            .socials {
                display: flex;
                align-items: center;

                a {
                    margin-right: 2.2rem;
                }
            }
        }
    }

    .map-mobile {
        width: 100%;
        height: auto;
        margin-bottom: 32px;

        @include is-pc {
            display: none;
        }
    }

    .map-pc {
        display: none;

        @include is-pc {
            display: block;
            width: 100%;
            height: auto;
            margin: 3rem 0 6.7rem;
        }
    }

    .contact-form {
        margin: 0 20px;

        @include is-pc {
            margin: 0;
        }

        &__header {
            font-size: 20px;
            margin-bottom: 22px;
            color: $navy-blue-text;
            text-align: center;

            @include is-pc {
                font-size: 2.86rem;
                margin-bottom: 3.5rem;
            }

            &-green {
                color: $green-text;
            }
        }

        &__inputs {
            display: flex;
            flex-direction: column;

            @include is-pc {
                width: 60.6%;
                min-width: 700px;
                margin: 0 auto;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 1.8rem 2.8rem;
            }

            input, textarea {
                font-family: inherit;
                background-color: #F1F9FF;
                border-radius: 1.5rem;
                border: none;
                font-size: 12px;
                padding: 13px 14px;
                margin-bottom: 7px;

                @include is-pc {
                    font-size: 1.43rem;
                    padding: 1.4rem 2.2rem;
                    margin-bottom: 0;
                }

                &::placeholder {
                    color: #8F8F8F;
                }

                &.full-width {
                    @include is-pc {
                        grid-column: span 2;
                    }
                }
            }

            textarea {
                resize: none;
                height: 146px;
                padding: 21px 14px;

                @include is-pc {
                    height: 21rem;
                    max-height: 28vh;
                    min-height: 200px;
                    padding: 2.1rem 2.2rem;
                }
            }
        }

        &__options {
            margin: 24px 0 72px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include is-pc {
                margin: .6rem auto 7rem;
                width: 60.6%;
                min-width: 700px;
                display: grid;
                grid-template-columns: auto 1fr auto;
            }

            &-file {
                position: relative;
                cursor: pointer;

                input {
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;

                    &:hover {
                        + button {
                            background-color: #3060C1;
                        }
                    }
                }

                &-btn {
                    @include button;
                    font-family: inherit;
                    background-color: $navy-blue-text;
                    color: #FFF;
                    padding: 0.3rem 1.6rem 0.1rem;
                    border-radius: .86rem;
                    font-size: 14px;
                    height: 28px;
                    cursor: pointer;

                    @include is-pc {
                        font-size: 1.43rem;
                        height: 2.8rem;
                    }

                    &:hover {
                        background-color: #3060C1;
                    }

                    img {
                        width: 17px;
                        margin-bottom: .2rem;
                        height: auto;

                        @include is-pc {
                            width: 1.66rem;
                        }
                    }
                }
            }

            &-terms {
                font-size: 14px;
                color: $brighter-main-text-color;
                margin: 13px 0 0;

                @include is-pc {
                    font-size: 1.43rem;
                    margin: 0 0 0 2rem;
                }

                .checkbox-container {
                    font-size: 14px;

                    @include is-pc {
                        font-size: .94rem;
                    }
                }

                a {
                    text-decoration: none;
                    color: $brighter-main-text-color;
                    margin: 0 .3rem;

                    &:hover {
                        text-decoration: underline;
                    }

                    &:visited {
                        color: $brighter-main-text-color;
                    }
                }
            }

            &-send {
                @include button;
                font-size: 20px;
                border: none;
                background-color: $navy-blue-text;
                color: #FFF;
                border-radius: 1.43rem;
                padding: 1rem 4rem;
                margin-top: 39px;
                height: 46px;

                @include is-pc {
                    font-size: 2.14rem;
                    margin-top: 1rem;
                    height: 4.74rem;
                }

                &:hover {
                    background-color: #3060C1;
                }
            }
        }
    }

    .partners {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include is-pc {
            padding: 6rem 0 7.5rem;
            background-image: url("/assets/partners_bg.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
        }

        &__header {
            font-size: 20px;
            margin-bottom: 2rem;
            color: $navy-blue-text;

            @include is-pc {
                font-size: 2.86rem;
            }


            &-green {
                color: $green-text;
            }
        }

        .logos-mobile {
            width: 100%;
            height: auto;
            mix-blend-mode: darken;

            @include is-pc {
                display: none;
            }
        }

        .logos-pc {
            width: 68%;
            height: auto;
            margin: 0 auto;
            mix-blend-mode: darken;
            display: none;

            @include is-pc {
                display: block;
            }
        }
    }
}

[lang="ar"] {
    @include is-pc {
        .main-info__address-text {
            text-align: right;
        }
    }
}