@import 'mixins.scss';
@import 'vars.scss';

.about-us {
    .header {
        background-position: top;
    }

    &__header {
        background-image: url("/assets/subpage_top_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: 45px;

        @include is-pc {
            padding: 5rem 0 0;
        }

        .header__nav-item {
            &:nth-of-type(3) {
                a {
                    color: $green-text;
                }
            }
        }
    }

    .mobile-menu__nav-item {
        &:nth-of-type(6) {
            a {
                color: $green-text;
            }
        }
    }

    &__top {
        text-align: center;
        padding: 60px 0 23px;

        @include is-pc {
            padding: 6.5rem 0 4rem;
        }

        &-h1 {
            font-size: 36px;
            color: $navy-blue-text;
            font-weight: normal;

            @include is-pc {
                font-size: 7.46rem;
            }
        }

        &-text {
            font-size: 14px;
            color: $navy-blue-text;
            margin-top: .5rem;

            @include is-pc {
                font-size: 1.98rem;
            }
        }
    }

    .section-header {
        font-size: 20px;
        color: $navy-blue-text;
        margin-bottom: 30px;
        text-align: center;

        @include is-pc {
            font-size: 2.86rem;
            margin-bottom: 2rem;
        }

        .green {
            color: $green-text;
        }
    }

    .main-info {
        display: flex;
        margin: 25px 0;

        @include is-pc {
            width: 79%;
            margin: 5.2rem auto 0;
        }

        .section-header {
            padding: 0 16px;
            margin-bottom: 4px;
            text-align: left;

            @include is-pc {
                padding: 0;
                margin-bottom: .8rem;
            }
        }

        &__left {
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include is-pc {
                width: 55%;
                margin-top: 2rem;
                padding: 0 6% 0 1%;
                text-align: left;
                display: block;
            }

            &-mobile-img {
                width: 99%;
                height: auto;
                left: 1%;
                position: relative;
                margin-bottom: 12px;

                @include is-pc {
                    display: none;
                }
            }
        }

        &__right {
            display: none;

            @include is-pc {
                display: block;
                width: 45%;
            }

            img {
                width: 100%;
            }
        }

        &__text {
            font-size: 14px;
            line-height: 24px;
            color: $brighter-main-text-color;
            margin-bottom: 22px;
            padding: 0 16px;

            @include is-pc {
                font-size: 1.35rem;
                line-height: 1.61rem;
                margin-bottom: 6.5rem;
                padding: 0;
            }
        }
    }

    .story {
        margin: 20px 0;

        .section-header {
            margin-bottom: 0;

            @include is-pc {
                margin-bottom: 2rem;
            }
        }

        @include is-pc {
            margin: 4.6rem auto 7rem;
        }

        &__movie {
            width: 100%;
            height: auto;
            margin: 2rem auto;

            @include is-pc {
                width: 70.7rem;
            }

            img {
                width: 110%;
                left: -4%;
                position: relative;

                @include is-pc {
                    position: static;
                }
            }
        }
    }

    .history {
        height: 485px;

        @include is-pc {
            height: auto;
        }

        .section-header {
            margin-bottom: 40px;

            @include is-pc {
                margin-bottom: 2rem;
            }
        }

        .timeline {
            display: none;
            position: relative;
            width: 91%;
            margin: 3.5rem auto 6rem;

            @include is-pc {
                display: block;
            }

            &::after {
                content: '';
                position: absolute;
                width: 1.5px;
                height: 100%;
                top: 0;
                bottom: 0;
                left: 50%;
                background: repeating-linear-gradient(0deg, transparent, transparent 15px, $navy-blue-text 9px, $navy-blue-text 30px);
            }

            &__step {
                padding: 1rem 3.5rem;
                position: relative;
                width: 50%;

                &::after {
                    content: '';
                    position: absolute;
                    width: 1.63rem;
                    height: 1.63rem;
                    top: 50%;
                    right: 0;
                    transform: translate(50%, -100%);
                    background: #ffffff;
                    border: 5px solid $navy-blue-text;
                    border-radius: 50%;
                    z-index: 1;
                }

                &.left {
                    left: 0;

                    .timeline__step-year {
                        right: -13rem;
                    }
                }

                &.right {
                    left: 50%;

                    .timeline__step-year {
                        left: -13rem;
                    }

                    &::after {
                        left: 0;
                        transform: translate(-50%, -100%);
                    }
                }

                &.first, &.last {
                    &:before {
                        content: '';
                        position: absolute;
                        width: 10px;
                        height: 50%;
                        background-color: #FFF;
                        z-index: 1;
                    }
                }

                &.first {
                    &:before {
                        bottom: 50%;
                        left: -5px;
                    }
                }


                &.second {
                    margin-top: -4rem;
                }

                &.third {
                    margin-top: -6.5rem;
                }

                &.last {
                    margin-top: -1.7rem;

                    &:before {
                        top: 50%;
                        right: -5px;
                    }
                }

                &-year {
                    position: absolute;
                    display: inline-block;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    font-size: 4rem;
                    color: $green-text;
                    z-index: 1;
                }
            }

            &__box {
                box-shadow: 7px 8px 16px rgba(#2E2D2B, .13);
                border-radius: 1.8rem;
                overflow: hidden;
                display: flex;

                &-img {
                    width: 43%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &-text {
                    flex: 1;
                    padding: 3.8rem 3rem 5.3rem;
                    font-size: 1.34rem;
                    line-height: 1.61rem;
                    color: $brighter-main-text-color;
                }
            }
        }

        .history-slider {
            position: relative;
            width: 100%;
            height: 82%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            flex: 1;

            @include is-pc {
                display: none;
            }

            &__slide {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                margin: 0 auto;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                visibility: hidden;

                &[data-is-active="false"] {
                    opacity: 0;
                    visibility: hidden;
                    transition: .5s linear;
                }

                &[data-is-active="true"] {
                    opacity: 1;
                    visibility: visible;
                    transition: .5s linear .5s;
                }
            }

            &__img {
                width: 100%;
                height: 124px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__year {
                font-size: 36px;
                color: $green-text;
                text-align: center;
                margin: 28px 0 2px;
            }

            &__text {
                font-size: 14px;
                line-height: 24px;
                color: $brighter-main-text-color;
                text-align: center;
                padding: 0 16px;
            }

            &__paging {
                width: auto;
                display: flex;
                align-items: center;
                justify-content: space-around;
                position: absolute;
                bottom: -24px;
                left: 50%;
                transform: translateX(-50%);

                &-item {
                    width: 36px;
                    height: 4px;
                    margin: 0 6px;
                    background-color: #DFE1ED;
                    border-radius: 3px;
                    cursor: pointer;
                    transition: background-color .3s ease-in-out;

                    &[data-is-active="true"] {
                        background-color: $green-text;
                    }
                }
            }
        }
    }

    .achievements {
        margin-top: 46px;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include is-pc {
            margin-top: 0;
            padding: 7.2rem 0 4.8rem;
            background-image: url("/assets/achievements_bg.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
        }

        .section-header {
            margin-bottom: 23px;

            @include is-pc {
                margin-bottom: 6.7rem;
            }
        }

        &__list {
            max-width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem 0;

            @include is-pc {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
            }
        }

        &__item {
            border: 3px solid $green-text;
            border-radius: 50%;
            color: $navy-blue-text;
            width: 151px;
            height: 151px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 8px;
            padding: 2.5rem 1rem 1rem;

            &:nth-of-type(3) {
                grid-column: span 2;
                margin: -21px auto;

                @include is-pc {
                    grid-column: none;
                    margin: 0;
                }
            }

            @include is-pc {
                width: 17rem;
                height: 17rem;
                margin: 0 1.5rem;
            }

            &-first-line {
                font-size: 47px;
                margin-bottom: .5rem;

                @include is-pc {
                    font-size: 5.38rem;
                }
            }

            &-second-line {
                font-size: 1.66rem;

                @include is-pc {
                    font-size: 14px;
                }
            }
        }
    }

    .partners {
        padding: 40px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @include is-pc {
            padding: 10.5rem 0 2.6rem;
            width: 91%;
        }

        .section-header {
            margin-bottom: 16px;

            @include is-pc {
                margin-bottom: 2rem;
            }
        }

        .logos-mobile {
            width: 100%;
            height: auto;
            mix-blend-mode: darken;

            @include is-pc {
                display: none;
            }
        }

        .logos-pc {
            width: 100%;
            height: auto;
            margin: 0 auto;
            mix-blend-mode: darken;
            display: none;

            @include is-pc {
                display: block;
            }
        }
    }
}