@import 'mixins.scss';
@import 'vars.scss';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background-image: url("/assets/home_bg.jpg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: bottom;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .09);

    @include is-pc {
        display: flex;
        align-items: flex-start;
        width: 94.5%;
        margin: 0 auto;
        padding: 0;
        position: static;
        background-image: none;
        box-shadow: none;
    }

    &__logo {
        z-index: 1;

        img {
            width: 112px;
            height: auto;
            cursor: pointer;

            @include is-pc {
                width: 16.3rem;
            }
        }

        &.mobile {
            @include is-pc {
                display: none;
            }
        }

        &.pc {
            display: none;

            @include is-pc {
                display: block;
            }
        }
    }

    &__nav {
        display: none;

        @include is-pc {
            display: flex;
            align-items: flex-start;

            &-list {
                list-style-type: none;
                display: flex;
                justify-content: space-between;
                margin-top: 1.2rem;
            }

            &-item {
                font-size: 1.35rem;
                font-weight: 500;
                color: $main-text-color;
                margin: 0 2.2rem;
                position: relative;

                a {
                    color: $main-text-color;
                    text-decoration: none;
                    cursor: pointer;
                    transition: color .2s ease-in-out;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 4px;
                        left: 0;
                        bottom: -1.25rem;
                        background-color: $green-text;
                        transition: all .3s ease-in-out;
                    }

                    &:hover {
                        color: $green-text;

                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .lang {
        display: flex;
        align-items: center;
        margin: 4px 10px 0 0;

        &.pc {
            display: none;

            @include is-pc {
                display: flex;
            }
        }

        @include is-pc {
            margin: .8rem 0 0 2.5rem;
        }

        &__earth {
            width: 1.5rem;
            height: 1.5rem;
            object-fit: contain;
        }

        &__key {
            font-size: 14px;
            font-weight: 600;
            padding: 7px 10px;
            text-decoration: none;
            color: $navy-blue-text;
            transition: color .2s ease;

            @include is-pc {
                font-size: 1rem;
                padding: .6rem;
                color: $main-text-color;
            }

            &:hover {
                color: $green-text;
            }

            &[data-is-active="true"] {
                color: $green-text;
            }

            &.arabic {
                margin-bottom: .4rem;
            }
        }


    }
}

.register-view {
    display: flex;
    flex-direction: column;
    padding: 2.8rem 0;
    height: 100%;
    min-height: 100vh;

    &__content {
        flex: 1;
        margin: 88px 0 20px;

        @include is-pc {
            display: flex;
            margin: 0;
        }
    }

    &__left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @include is-pc {
            width: 46%;
            padding: 7rem 0 7rem 3%;
        }
    }

    &__header {
        font-size: 32px;
        line-height: 36px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 2.7rem;
        padding: 0 22px;

        @include is-pc {
            font-size: 3.57rem;
            line-height: 4.7rem;
            width: 38rem;
            max-width: 100%;
            padding: 0;
        }
    }

    &__right {
        height: auto;
        margin-top: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 35px 22px 0;

        @include is-pc {
            width: 54%;
            height: auto;
            justify-content: center;
            margin: 0;
            padding-right: 3%;
        }

        &-img {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: url("/assets/register_graphic_bg.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            @include is-pc {
                width: 90%;
                height: 90%;
                margin-top: 3.5%;
            }

            .video {
                width: 100%;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                border-radius: 17px;

                @include is-pc {
                    width: 95%;
                    border-radius: 2rem;
                }
            }

            img {
                width: 65%;
                height: auto;
                max-height: 62%;
                object-fit: contain;
                position: relative;
                left: 2%;
                top: -3%;
            }
        }
    }

    .register-view-form {
        width: 38rem;
        max-width: calc(100% - 44px);
        position: relative;
        background-color: #FFF;
        border-radius: 17px;
        box-shadow: 0 23px 36px rgba(0, 0, 0, .15);
        padding: 3.7rem 3rem 3rem;
        margin: 0 auto;
        overflow: hidden;

        &:before {
            content: "";
            height: 6px;
            background-color: #05d94e;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        &__header {
            font-size: 1.57rem;
            font-weight: 600;
            margin-bottom: 2.7rem;
        }

        &__label {
            display: block;
            font-size: 1rem;
            margin-bottom: 1rem;
            font-weight: 600;
        }

        &__input {
            font-family: inherit;
            font-size: 16px;
            font-weight: 500;
            width: 100%;
            min-height: 4.25rem;
            border-radius: 6px;
            border: 1px solid #c1c1c1;
            color: $navy-blue-text;
            padding: 1rem 1.7rem;
            margin-bottom: .5rem;
            outline: none;
            transition: border-color .15s ease-in;

            @include is-pc {
                font-size: 1rem;
            }

            &:focus {
                border: 1px solid $navy-blue-text;
                outline: 0;
            }
        }

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 0 1rem;

            &-text {
                font-size: 1rem;
                font-weight: 600;
                color: $brighter-main-text-color;
                margin-top: 2.4rem;

                a {
                    color: $navy-blue-text;
                    text-decoration: none;
                    transition: color .12s ease-in;

                    &:hover {
                        color: $green-text;
                    }
                }
            }

            &-btn {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $navy-blue-text;
                font-family: inherit;
                font-size: 1.23rem;
                color: #FFF;
                border: none;
                margin: 2.3rem 0 0;
                min-height: 3.29rem;
                border-radius: 6px;
                padding: .5rem 1.15rem;
                transition: all .25s ease-in-out;
                outline: none;
                user-select: none;
                cursor: pointer;

                &:hover {
                    background-color: #3060c1;
                }

                img {
                    margin: 0 .6rem;
                }
            }
        }
    }

    &__footer {
        font-size: 12px;
        line-height: 1.3;
        color: #898989;
        padding: 30px 22px 0;
        text-align: center;

        @include is-pc {
            font-size: 1.07rem;
            padding: 0 2rem;
        }
    }
}

[lang="ar"] {
    @include is-pc {
        .register-view__right {
            padding-right: 0;
            padding-left: 3%;
        }

        .register-view__left {
            padding: 7rem 3% 7rem 0;
        }
    }
}

.home {
    background-image: url("/assets/home_bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    min-height: 640px;
    max-height: 850px;
    padding: 20px 0 101px;

    @include is-pc {
        padding: 2rem 0 5.5rem;
        height: 100vh;
        min-height: 700px;
        max-height: 976px;
    }

    .header {
        &__nav-item {
            &:nth-of-type(1) {
                a {
                    color: $green-text;
                }
            }
        }
    }

    .mobile-menu__nav-item {
        &:nth-of-type(1) {
            a {
                color: $green-text;
            }
        }
    }

    .slider {
        position: relative;
        width: 100%;
        height: calc(100% - 52px);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex: 1;

        @include is-pc {
            width: 86%;
            height: 100%;
        }

        &__bottom {
            width: 100%;
            height: 100px;
            margin: 0;
            padding: 9px 20px 65px;
            position: absolute;
            bottom: -151px;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;

            @include is-pc {
                height: 4.36rem;
                flex-direction: row;
                align-items: initial;
                justify-content: space-between;
                margin: 4% 0;
                padding: 0;
                bottom: 0;
            }

            &-btn {
                @include button;
                border: 1px solid #DFE1ED;
                font-size: 15px;
                font-weight: bold;
                color: $navy-blue-text;
                background-color: transparent;
                width: fit-content;
                width: -moz-fit-content;
                height: 42px;
                padding: 1rem 1.6rem;
                border-radius: 11px;

                @include is-pc {
                    width: auto;
                    font-size: 1.57rem;
                    height: 4.36rem;
                    border-radius: 16px;
                }

                &:hover {
                    background-color: $navy-blue-text;
                    color: #FFF;
                }

                img {
                    width: 1.88rem;
                    height: auto;
                    margin-left: 10px;

                    @include is-pc {
                        margin: 0 1rem;
                    }
                }

                span, img {
                    pointer-events: none;
                }
            }

            &-paging {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 36px;

                @include is-pc {
                    width: 60%;
                    margin-bottom: 0;
                }

                &-item {
                    width: 36px;
                    height: 4px;
                    background-color: #DFE1ED;
                    border-radius: 3px;
                    margin: 0 .5rem;
                    cursor: pointer;
                    transition: background-color .3s ease-in-out;

                    @include is-pc {
                        width: 2.57rem;
                        height: .42rem;
                    }

                    &[data-is-active="true"] {
                        background-color: $green-text;
                    }
                }
            }

            &-chat {
                display: none;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                width: 4.45rem;
                height: auto;
                transition: transform .2s ease-in-out;

                @include is-pc {
                    display: block;
                }

                &:hover {
                    transform: translateY(-60%);
                }
            }
        }
    }

    .slide {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;

        @include is-pc {
            flex-direction: row;
            height: calc(100% - 4.36rem - 8%);
        }

        &[data-is-active="false"] {
            opacity: 0;
            visibility: hidden;
            transition: .5s linear;
        }

        &[data-is-active="true"] {
            opacity: 1;
            visibility: visible;
            transition: .5s linear .5s;
        }

        &__left {
            width: 100%;
            height: 42%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin: 12px 0 0;
            padding: 0 17px;

            @include is-pc {
                width: fit-content;
                width: -moz-fit-content;
                height: auto;
                min-width: 51rem;
                align-items: flex-start;
                margin: 0;
                padding: 0;
            }

            &-texts {
                margin: auto 0;
            }

            &-header {
                font-size: 36px;
                font-weight: normal;
                color: $navy-blue-text;

                @include is-pc {
                    font-size: 6.43rem;
                    white-space: nowrap;
                }
            }

            &-subheader {
                font-size: 20px;
                line-height: 24px;
                color: $green-text;
                margin: 11px 0;

                @include is-pc {
                    font-size: 2.93rem;
                    line-height: 3.5rem;
                    margin: 1.7rem 0 3rem;
                }
            }

            &-under-subheader {
                font-size: 14px;
                line-height: 17px;
                color: $navy-blue-text;

                @include is-pc {
                    font-size: 1.98rem;
                    line-height: 2.36rem;
                }
            }
        }

        &__right {
            width: 100%;
            height: 58%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            flex: 1;

            @include is-pc {
                width: auto;
                height: auto;
                max-height: 100%;
                align-items: center;
            }

            &-img {
                width: auto;
                height: 93%;
                max-height: 93%;

                @include is-pc {
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}

[lang="ar"] {
    .slide__left-texts {
        text-align: center;

        @include is-pc {
            padding-right:  5%;
            margin-left: auto;
            text-align: right;
        }
    }

    .slide--1 {
        .slide__left-texts {
            @include is-pc {
                padding-right: 6%;
            }
        }
    }
}

.join-modal {
    width: 91%;
    max-width: 340px;
    margin: 0 auto;
    padding: 45px 14px 30px;
    background-color: #fff;
    border-radius: 2rem;
    box-shadow: 3px 4px 90px rgba(#263C8A, .32);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;


    @include is-pc {
        width: 39rem;
        max-width: initial;
        margin: 5rem auto;
        padding: 3.9rem 1rem 3rem;
    }

    &__bg-people {
        display: none;

        @include is-pc {
            display: block;
            width: 84rem;
            height: auto;
            position: absolute;
            margin: auto;
            margin: 5rem auto;
            top: 20%;
        }
    }

    &-container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        min-width: 100%;
        min-height: 100%;
        overflow: auto;
        margin: auto;
        display: none;
        align-items: center;
        justify-content: center;
        bottom: 0;
        background-image: url("/assets/modal_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: top center;
        z-index: 11;
        animation: fade-in .2s ease-in-out;
        opacity: 0;

        &[data-is-active="true"] {
            display: flex;
            opacity: 1;
        }

        @include is-pc {
            overflow: hidden;
        }
    }

    &__header {
        color: $green-text;
        font-size: 20px;
        margin-bottom: 20px;

        @include is-pc {
            font-size: 1.75rem;
            margin-bottom: 2.7rem;
        }
    }

    &__close {
        width: 44px;
        height: 44px;
        background-color: #FFF;
        font-size: 1.5rem;
        color: $navy-blue-text;
        position: absolute;
        box-shadow: 3px 4px 90px rgba(#263C8A, .32);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        top: 0;
        left: 50%;
        right: 0;
        transform: translate(-50%, -50%);

        @include is-pc {
            width: 3.14rem;
            height: 3.14rem;
            top: -1.2rem;
            right: -1.2rem;
            left: auto;
            transform: none;
        }

        img {
            width: 18px;
            height: 18px;
            pointer-events: none;
        }
    }

    &__fields {
        width: 100%;

        @include is-pc {
            width: auto;
        }
    }

    &__field {
        display: flex;
        flex-direction: column;

        &:nth-of-type(3) {
            margin-top: 8px;
        }
    }

    &__label {
        color: $navy-blue-text;
        font-size: 14px;
        margin-bottom: .7rem;

        @include is-pc {
            font-size: 1.23rem;
        }
    }

    &__input {
        background-color: #F1F9FF;
        font-size: 16px;
        font-family: inherit;
        border-radius: 1.23rem;
        border: none;
        margin-bottom: 26px;
        padding: 8px 12px;

        @include is-pc {
            font-size: 1.23rem;
            width: 32.9rem;
            margin-bottom: 2.7rem;
            padding: 1.12rem;
        }

        &::placeholder {
            color: #8F8F8F;
        }
    }

    &__checkboxes {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        margin: 10px 0 18px;

        @include is-pc {
            flex-direction: row;
            align-items: center;
            margin: 2rem 0;
        }

        .checkbox-radio {
            font-size: 16px;
            color: $brighter-main-text-color;

            @include is-pc {
                font-size: 1.23rem;
            }

            .checkbox-radio-option {
                margin-right: 0;
            }
        }
    }

    &__button {
        @include button;
        font-size: 20px;
        background-color: $navy-blue-text;
        color: #FFF;
        border-radius: 1.16rem;
        width: 259px;
        height: 42px;
        margin: 0 auto;
        text-align: center;
        justify-content: center;

        @include is-pc {
            width: 20.5rem;
            height: 3.9rem;
            font-size: 1.78rem;
        }

        &:hover {
            background-color: #3060C1;
        }
    }
}