@import 'mixins.scss';
@import 'vars.scss';

.take-care {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    @include is-pc {
        width: 91%;
        padding: 0;
    }

    &__logos {
        margin: 6rem auto 0;
        width: 100%;
    }

    &__header {
        font-size: 36px;
        line-height: 43px;
        font-weight: normal;
        color: $navy-blue-text;
        margin: 48px 0 7px;
        text-align: center;

        @include is-pc {
            font-size: 5.22rem;
            line-height: inherit;
            margin: 9rem 0 .5rem;
        }
    }

    &__subheader {
        font-size: 20px;
        line-height: 24px;
        color: $brighter-main-text-color;
        text-align: center;

        @include is-pc {
            font-size: 2.52rem;
            line-height: inherit;
        }
    }

    &__steps {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 29px 0 60px;
        position: relative;

        @include is-pc {
            margin: 9.7rem 0 2.7rem;
            align-items: baseline;
        }

        &.on-mobile {
            height: 230px;

            .take-care__step {
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                visibility: hidden;
                justify-content: center;

                &[data-is-active="false"] {
                    opacity: 0;
                    visibility: hidden;
                    transition: .5s linear;
                }

                &[data-is-active="true"] {
                    opacity: 1;
                    visibility: visible;
                    transition: .5s linear .5s;
                }

                &-img {
                    width: auto;
                    max-width: 100%;
                    height: 115px;
                    max-height: 100%;
                }
            }

            .take-care__paging {
                width: auto;
                display: flex;
                align-items: center;
                justify-content: space-around;
                position: absolute;
                bottom: -9px;
                left: 50%;
                transform: translateX(-50%);

                &-item {
                    width: 36px;
                    height: 4px;
                    margin: 0 6px;
                    background-color: #DFE1ED;
                    border-radius: 3px;
                    cursor: pointer;
                    transition: background-color .3s ease-in-out;

                    &[data-is-active="true"] {
                        background-color: $green-text;
                    }
                }
            }
        }
    }

    &__step {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 40px;

        @include is-pc {
            width: 17%;
            margin-bottom: 4rem;
        }

        &-img {
            width: auto;
            max-width: 100%;
            height: 8.29rem;
        }

        &-desc {
            font-size: 14px;
            line-height: 17px;
            color: $brighter-main-text-color;
            text-align: center;
            margin: 30px 0 0;
            width: 58%;

            @include is-pc {
                font-size: 1.7rem;
                line-height: 2rem;
                margin: 2.5rem 1.5rem 0;
                width: auto;
            }
        }
    }

    &__link {
        text-decoration: none;
        margin-bottom: 20px;

        @include is-pc {
            margin-bottom: 7rem;
        }
    }

    &__button {
        @include button;
        background-color: $navy-blue-text;
        padding: 0;
        font-size: 15px;
        font-weight: 600;
        color: #FFF;
        overflow: hidden;
        border-radius: 1.07rem;
        height: 42px;

        @include is-pc {
            font-size: 1.56rem;
            height: 4.3rem;
        }

        &-text {
            padding: 0 2.25rem;
            line-height: 1;

            @include is-pc {
                line-height: normal;
            }
        }

        &-icon {
            background-color: $green-text;
            padding: 0 1rem;
            height: 4.3rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 1.86rem;
                height: auto;
            }
        }

        &:hover {
            background-color: #3060c1;
        }
    }
}