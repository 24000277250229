@import 'mixins.scss';
@import 'vars.scss';

.how-it-works {
    .header {
        background-position: top;
    }

    &__header {
        background-image: url("/assets/subpage_top_bg.png");
        background-size: 100% 203px;
        background-repeat: no-repeat;
        background-position: top center;
        padding: 0;
        margin-bottom: 37px;
        padding-top: 45px;

        @include is-pc {
            background-size: 100% 100%;
            background-position: center;
            margin-bottom: 14rem;
            padding: 5rem 0 0;
        }

        .header__nav-item {
            &:nth-of-type(2) {
                a {
                    color: $green-text;
                }
            }
        }
    }

    &__top {
        text-align: center;
        padding: 60px 0 23px;

        @include is-pc {
            padding: 6.5rem 0 4rem;
        }

        &-h1 {
            font-size: 36px;
            color: $navy-blue-text;
            font-weight: normal;

            @include is-pc {
                font-size: 7.46rem;
            }
        }

        &-text {
            font-size: 14px;
            color: $navy-blue-text;
            margin-top: .5rem;

            @include is-pc {
                font-size: 1.98rem;
            }
        }
    }

    &__main-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include is-pc {
            transform: translateY(50%);
            flex-direction: row;
        }
    }

    &__main-button {
        @include button;
            justify-content: center;
            width: 260px;
            height: 42px;
            margin: 0 0 11px;
            font-size: 20px;
            font-weight: 600;
            color: $navy-blue-text;
            border: none;
            border-radius: .8rem;
            box-shadow: 7px 8px 16px rgba(#2E2D2B, .13);
            text-decoration: none;
            background-color: #FFF;

            @include is-pc {
                font-size: 1.98rem;
                width: 20.2rem;
                height: 5.21rem;
                margin: 0 1rem;
            }

        &.active {
            background-color: $green-text;
            color: #FFF;
        }
    }

    .steps {
        position: relative;
        margin: 0 auto;
        overflow: hidden;

        @include is-pc {
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 2.1%;
                bottom: 0;
                left: -22.7%;
                background-image: url("assets/client_path.svg");
                background-repeat: no-repeat;
                background-size: 92% auto;
                background-position: top;
            }
        }
    }

    .step {
        padding: 0;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1;
        margin: 0 0 40px;
        height: auto;

        @include is-pc {
            width: 76%;
            display: grid;
            align-items: center;
            grid-template-columns: 50% 50%;
            margin: 0 auto;
        }

        &__graphic {
            position: relative;
            width: 100%;
            height: auto;
        }

        &__text {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            height: auto;
            flex: 1;
            position: relative;
            padding: 0 17px;
            text-align: center;

            @include is-pc {
                padding: 0;
                justify-content: flex-start;
                text-align: left;
                display: grid;
                align-items: baseline;

                > div {
                    display: flex;
                    align-items: baseline;
                }
            }

            &-number {
                font-size: 20px;
                color: $green-text;
                margin: 0 .5rem;

                @include is-pc {
                    font-size: 4.07rem;
                }
            }

            &-header {
                font-size: 20px;
                color: $navy-blue-text;

                @include is-pc {
                    font-size: 2.86rem;
                }
            }

            &-subheader {
                font-size: 14px;
                line-height: 17px;
                color: $brighter-main-text-color;
                grid-area: 1/2;
                margin-top: 5px;
                width: 100%;

                @include is-pc {
                    font-size: 1.36rem;
                    line-height: 1.6rem;
                    grid-area: 2/2;
                    margin-top: .7rem;
                }
            }
        }

        &.center {
            margin-top: 12px;
            display: flex;
            align-items: center;
            flex-direction: column;

            @include is-pc {
                margin-top: 3.7rem;
            }

            .step {
                &__text {
                    width: auto;
                    flex-direction: column;

                    &-subheader {
                        @include is-pc {
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }

        // @include is-pc {
        //     &.first {
        //         .step__text-subheader {
        //             margin-left: 5.2rem;
        //         }
        //     }

        //     &.second,
        //     &.third {
        //         .step__text-subheader {
        //             margin-left: 5.9rem;
        //         }
        //     }

        //     &.fourth,
        //     &.fifth {
        //         .step__text-subheader {
        //             margin-left: 6rem;
        //         }
        //     }
        // }
    }

    .steps.client {
        @include is-pc {
            &::after {
                top: 2.1%;
                bottom: 0;
                left: -22.7%;
                background-image: url("assets/client_path.svg");
                background-repeat: no-repeat;
                background-size: 92% auto;
                background-position: top;
            }
        }

        .step {
            &.first {
                @include is-pc {
                    left: 0;
                    width: 76%;
                }

                .step {
                    &__text {
                        @include is-pc {
                            top: -10%;
                        }
                    }

                    &__graphic {
                        width: 91%;
                        margin: 0 0 0 auto;
                        top: 10px;

                        @include is-pc {
                            width: 112%;
                            height: auto;
                            top: initial;
                            margin: 1% 0 0 7.5%;
                        }
                    }
                }
            }

            &.second {
                flex-direction: column-reverse;

                @include is-pc {
                    margin-top: -8%;
                    flex-direction: column;
                }

                .step {
                    &__text {
                        @include is-pc {
                            top: 0;
                            left: 8%;
                        }
                    }

                    &__graphic {
                        width: 80%;
                        margin: 0 auto;
                        top: 18px;

                        @include is-pc {
                            width: 93%;
                            margin: 0;
                            top: initial;
                            left: -1%;
                        }
                    }
                }
            }

            &.third {
                .step {
                    &__graphic {
                        width: 83%;
                        margin: 0 auto;
                        top: 14px;
                        left: 5px;

                        @include is-pc {
                            width: 98%;
                            margin: 0;
                            top: 6%;
                            left: 8%;
                        }
                    }
                }
            }

            &.fourth {
                flex-direction: column-reverse;

                @include is-pc {
                    margin-top: -1.5%;
                    flex-direction: column;
                }

                .step {
                    &__text {
                        @include is-pc {
                            left: 8%;
                        }
                    }

                    &__graphic {
                        width: 80%;
                        margin: 0 auto;
                        top: 15px;

                        @include is-pc {
                            width: 95%;
                            margin: 0;
                            top: 12%;
                            left: -1%;
                        }
                    }
                }
            }

            &.fifth {
                @include is-pc {
                    margin-top: -1.8%;
                }

                .step {
                    &__graphic {
                        width: 66%;
                        margin: 0 auto;
                        top: 14px;
                        left: 5px;

                        @include is-pc {
                            width: 95%;
                            margin: 0;
                            top: -5%;
                            left: 11%;
                        }
                    }
                }
            }
        }

        + .center {
            .step {
                &__graphic {
                    width: 98%;
                    margin: 0 auto;
                    top: -1px;
                    left: -8px;

                    @include is-pc {
                        width: 70.5%;
                        margin: 1rem 0 5rem;
                    }
                }
            }
        }
    }

    .steps.contractor {
        &::after {
            top: -.8%;
            bottom: 0;
            left: 0;
            background-image: url("assets/contractor_path.svg");
            background-repeat: no-repeat;
            background-size: 144% 100%;
            background-position: 43.5% top;
        }

        .step {
            &.first {
                left: 0;

                @include is-pc {
                    width: 76%;
                }

                .step {
                    &__text {
                        top: -10%;
                    }

                    &__graphic {
                        width: 91%;
                        margin: 0 0 0 auto;
                        top: 10px;

                        @include is-pc {
                            width: 112%;
                            height: auto;
                            top: initial;
                            margin: 1% 0 0 7.5%;
                        }
                    }
                }
            }

            &.second {
                flex-direction: column-reverse;

                @include is-pc {
                    margin-top: -5%;
                    flex-direction: column;
                }

                .step {
                    &__text {
                        @include is-pc {
                            top: 2%;
                            left: 8%;
                        }
                    }

                    &__graphic {
                        width: 86%;
                        top: 2px;
                        margin: 0 auto;

                        @include is-pc {
                            width: 128%;
                            top: 7%;
                            left: -1.5%;
                            margin: 0;
                        }
                    }
                }
            }

            &.third {
                @include is-pc {
                    margin-top: 2.6%;
                }

                .step {
                    &__graphic {
                        width: 82%;
                        top: 18px;
                        left: -18px;
                        margin: 0 auto;

                        @include is-pc {
                            width: 98%;
                            top: 10%;
                            left: 4%;
                            margin: 0;
                        }
                    }
                }
            }

            &.fourth {
                flex-direction: column-reverse;

                @include is-pc {
                    margin-top: -1.6%;
                    flex-direction: column;
                }

                .step {
                    &__text {
                        @include is-pc {
                            left: 8%;
                        }
                    }

                    &__graphic {
                        width: 86%;
                        top: 20px;
                        left: 7px;
                        margin: 0 auto;

                        @include is-pc {
                            width: 94%;
                            top: 10%;
                            left: -2%;
                            margin: 0;
                        }
                    }
                }
            }

            &.fifth {
                @include is-pc {
                    margin-top: 1.2%;
                    margin-bottom: 19%;
                }

                .step {
                    &__text {
                        @include is-pc {
                            top: 9%;
                            left: -8.5%;
                            max-width: 92%;
                        }
                    }

                    &__graphic {
                        width: 82%;
                        top: 14px;
                        margin: 0 auto;

                        @include is-pc {
                            width: 93%;
                            top: 6%;
                            left: 7.5%;
                            margin: 0;
                        }
                    }
                }
            }
        }

        + .center {
            @include is-pc {
                margin-top: -6.8%;
            }

            .step {
                &__graphic {
                    width: 100%;
                    left: 10px;
                    margin: 0 auto;

                    @include is-pc {
                        width: 81%;
                        margin: -1rem 0 5rem;
                    }
                }
            }
        }
    }

    .steps.consultant {
        &::after {
            top: -12.8%;
            bottom: 0;
            left: 0;
            background-image: url("assets/consultant_path.svg");
            background-repeat: no-repeat;
            background-size: 111% 100%;
            background-position: 163.5% top;
        }

        .step {
            &.first {
                left: 0;

                @include is-pc {
                    width: 76%;
                }

                .step {
                    &__text {
                        @include is-pc {
                            top: -10%;
                        }
                    }

                    &__graphic {
                        width: 91%;
                        margin: 0 0 0 auto;
                        top: 10px;

                        @include is-pc {
                            width: 112%;
                            height: auto;
                            top: initial;
                            margin: 1% 0 0 7.5%;
                        }
                    }
                }
            }

            &.second {
                flex-direction: column-reverse;

                @include is-pc {
                    margin-top: -5.5%;
                    flex-direction: column;
                }

                .step {
                    &__text {

                        @include is-pc {
                            top: 0;
                            left: 9%;
                        }
                    }

                    &__graphic {
                        width: 84%;
                        top: 19px;
                        left: 5px;
                        margin: 0 auto 9px;

                        @include is-pc {
                            width: 137%;
                            top: 8%;
                            left: -12%;
                            margin: 0;
                        }
                    }
                }
            }

            &.third {
                @include is-pc {
                    margin-top: 5%;
                }

                .step {
                    &__graphic {
                        width: 91%;
                        top: 15px;
                        left: 5px;
                        margin: 0 auto -6px;

                        @include is-pc {
                            width: 103%;
                            top: -2%;
                            left: 8%;
                            margin: 0;
                        }
                    }
                }
            }

            &.fourth {
                flex-direction: column-reverse;

                @include is-pc {
                    margin-top: -7%;
                    flex-direction: column;
                }

                .step {
                    &__text {
                        @include is-pc {
                            left: 6%;
                        }
                    }

                    &__graphic {
                        width: 84%;
                        top: 19px;
                        left: 5px;
                        margin: 0 auto 9px;

                        @include is-pc {
                            width: 101%;
                            top: 17%;
                            left: -2%;
                            margin: 0;
                        }
                    }
                }
            }

            &.center {
                @include is-pc {
                    margin-top: 16.5%;
                }

                .step {
                    &__text {
                        @include is-pc {
                            max-width: 60%;
                        }
                    }

                    &__graphic {
                        width: 95%;
                        top: 0;
                        left: 4px;
                        margin: 0 auto;

                        @include is-pc {
                            width: 83%;
                            margin: -1rem 0 5rem;
                        }
                    }
                }
            }
        }
    }

    ~ .footer {
        padding: 3rem 0 3rem;
    }
}

[lang="ar"] {
    .step__text {
        @include is-pc {
            text-align: right;

            &-number {
                margin-left: 1.5rem;
            }
        }
    }
}