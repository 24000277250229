.testimonials {
    height: 630px;
    min-height: 700px;
    max-height: 976px;
    padding: 0 4px;
    margin: 59px 0 41px;
    display: flex;
    flex-flow: column;
    align-items: center;

    @include is-pc {
        margin: 0;
        padding: 0;
        display: block;
        height: 100vh;
        min-height: 700px;
        max-height: 976px;
    }

    &__header {
        font-size: 36px;
        font-weight: normal;
        color: $navy-blue-text;
        text-align: center;

        @include is-pc {
            font-size: 5.22rem;
            width: 49%;
            margin: 10.5% 2rem 0 auto;
            padding: 0 1% 0 4.5%;
            text-align: left;
        }
    }

    .slider {
        display: none;

        @include is-pc {
            display: flex;
            position: relative;
            width: 100%;
            height: 50rem;
            min-height: 400px;
            max-height: 66vh;
            flex-direction: column;
            align-items: flex-end;
            flex: 1;

            &__container {
                width: 100%;
                height: 50rem;
                min-height: 400px;
                max-height: 66vh;
                margin-top: -15rem;
                pointer-events: none;
            }

            .slide {
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                visibility: hidden;
                width: 100%;
                height: 50rem;
                min-height: 400px;
                max-height: 66vh;

                &[data-is-active="false"] {
                    opacity: 0;
                    visibility: hidden;
                    transition: all .5s linear;
                }

                &[data-is-active="true"] {
                    opacity: 1;
                    visibility: visible;
                    transition: all .5s linear .5s;
                }

                &__left {
                    width: 51%;
                    height: 100%;
                    border-radius: 0 32px 32px 0;
                    box-shadow: 7px 8px 16px rgba(#2E2D2B, .13);
                    overflow: hidden;
                    position: relative;
                    top: -15rem;

                    &-dots {
                        position: absolute;
                        display: flex;
                        bottom: 3rem;
                        right: 2.6rem;
                        z-index: 1;
                    }

                    &-dot {
                        width: 1.5rem;
                        height: 1.5rem;
                        background-color: #FFF;
                        border-radius: 50%;
                        margin: 0 .5rem;
                        cursor: pointer;

                        &[data-is-active="true"] {
                            background-color: $green-text;
                        }
                    }

                    .img-slider {
                        width: 100%;
                        height: 100%;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            object-fit: cover;
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            visibility: hidden;

                            &[data-is-active="false"] {
                                opacity: 0;
                                visibility: hidden;
                                transition: all .5s linear;
                            }

                            &[data-is-active="true"] {
                                opacity: 1;
                                visibility: visible;
                                transition: all .5s linear .5s;
                            }
                        }
                    }
                }

                &__right {
                    width: 49%;
                    padding: 0 3% 0 4.5%;
                    overflow: auto;
                    margin: 2rem 2rem 12rem 0;

                    &-text {
                        color: $brighter-main-text-color;
                        font-size: 1.72rem;
                        line-height: 2.3rem;

                        &.blue {
                            color: $navy-blue-text;
                            font-size: 2.43rem;
                            line-height: 2.8rem;
                        }

                        p {
                            margin: 1.5rem 0;
                        }
                    }
                }
            }

            
        }
    }

    .slider-mobile {
        display: flex;
        position: relative;
        width: 100%;
        flex-direction: column;
        align-items: flex-end;
        flex: 1;

        @include is-pc {
            display: none;
        }

        .slide {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
            width: 100%;
            height: 94%;

            &[data-is-active="false"] {
                opacity: 0;
                visibility: hidden;
                transition: all .5s linear;
            }

            &[data-is-active="true"] {
                opacity: 1;
                visibility: visible;
                transition: all .5s linear .5s;
            }

            &__left {
                width: 100%;
                height: 271px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                &-img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                }
            }

            &__right {
                padding: 0 10px;

                &-text {
                    font-size: 14px;
                    line-height: 17px;
                    color: $brighter-main-text-color;
                    text-align: center;

                    &.blue {
                        color: $navy-blue-text;
                        margin-bottom: 17px;
                    }

                    p {
                        margin: 15px 0;
                    }
                }
            }
        }

        .paging {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: absolute;
            bottom: -4px;
            left: 50%;
            transform: translate(-50%, 100%);

            &-item {
                width: 36px;
                height: 4px;
                margin: 0 6px;
                background-color: #DFE1ED;
                border-radius: 3px;
                cursor: pointer;
                transition: background-color .3s ease-in-out;

                &[data-is-active="true"] {
                    background-color: $green-text;
                }
            }
        }
    }

    &__list {
        width: 80%;
        margin: 0 10%;
        display: flex;
        align-items: center;
        position: relative;
        top: 14%;
        left: 0;
    }

    &__item {
        width: fit-content;
        width: -moz-fit-content;
        min-width: 209px;
        max-width: 100%;
        border-radius: 13px;
        box-shadow: 7px 8px 16px rgba(#2E2D2B, .13);
        padding: 18px 20px;
        margin: 20px 0 0;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        cursor: pointer;
        transition: transform .2s ease-in;
        position: relative;

        @include is-pc {
            width: 21.5%;
            min-width: 22rem;
            max-width: initial;
            border-radius: 1.43rem;
            align-items: initial;
            padding: 2.3rem 4rem;
            margin: 0 2rem;
        }

        * {
            pointer-events: none;
        }

        @include is-pc {
            &::after {
                content: "";
                position: absolute;
                width: 0;
                height: 5px;
                bottom: -2rem;
                background-color: $green-text;
                opacity: 0;
                transition: all .3s ease-in-out;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &-avatar {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 50%;
            object-fit: cover;
            grid-row: span 2;
            margin-right: 1.5rem;

            @include is-pc {
                margin-right: 1.5rem;
            }
        }

        &-name {
            font-size: 13px;
            font-weight: 600;

            @include is-pc {
                font-size: 1.3rem;
                font-weight: 500;
                margin: .5rem 0 0;
            }
        }

        &[data-is-active="true"] {
            transform: translateY(-1.5rem);

            &::after {
                opacity: 1;
                width: 95%;
            }
        }
    }

    .stars {
        display: flex;

        img {
            width: 1.1rem;
            height: auto;
            margin-right: .5rem;
        }
    }
}