.footer {
    width: 86%;
    margin: 0 auto;
    padding: 20px 0;

    @include is-pc {
        padding: 6.5rem 0 3rem;

        &.key {
            text-align: right;

            .footer__main {
                text-align: right;
            }
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @include is-pc {
            display: grid;
            grid-template-columns: 19% auto 1fr auto;
            grid-gap: 3%;
            align-items: initial;
            text-align: left;
        }
    }

    &__logo {
        display: none;

        @include is-pc {
            display: block;
            width: 16.7rem;
            height: auto;
        }
    }

    &__col-header {
        font-size: 20px;
        color: $navy-blue-text;
        margin: 1rem 0 2rem;

        @include is-pc {
            font-size: 2.14rem;
        }
    }

    &__contact {
        display: none;

        @include is-pc {
            display: block;
        }

        &-row {
            display: flex;
            align-items: center;
            margin: 0 0 1rem .5rem;

            img {
                width: 1.71rem;
                height: 1.71rem;
                margin: 0 .5rem;
            }

            a {
                color: $navy-blue-text;
                text-decoration: none;
                font-size: 1.6rem;
                transition: color .2s ease;
                direction: ltr;

                &:hover {
                    color: $green-text;
                }

                &:visited {
                    color: $navy-blue-text;
                }
            }
        }
    }

    &__address {
        color: $brighter-main-text-color;
        font-size: 14px;
        line-height: 30px;

        @include is-pc {
            font-size: 1.34rem;
            line-height: 2.14rem;
            margin-left: 14%;
        }

        .footer__col-header {
            font-size: 20px;
            margin: 20px 0 7px;
            color: $navy-blue-text;

            @include is-pc {
                font-size: 2.14rem;
                margin: 1rem 0 1.4rem;
            }
        }
    }

    &__text {
        margin-top: 3rem;
        color: $brighter-main-text-color;
    }

    &__visit {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: 39px;

        @include is-pc {
            align-items: flex-end;
            margin-top: 0;
        }

        &-media {
            display: flex;
            align-items: center;
        }

        &-link {
            margin: 0 9px;

            @include is-pc {
                margin: 0 0 0 1.2rem;
            }

            &:nth-of-type(1) {
                img {
                    width: 25px;
                    height: auto;

                    @include is-pc {
                        width: 2rem;
                    }
                }
            }

            &:nth-of-type(2) {
                img {
                    width: 22px;
                    height: auto;

                    @include is-pc {
                        width: 1.9rem;
                    }
                }
            }

            &:nth-of-type(3) {
                img {
                    width: 20px;
                    height: auto;

                    @include is-pc {
                        width: 1.8rem;
                    }
                }
            }

            &:nth-of-type(4) {
                img {
                    width: 26px;
                    height: auto;

                    @include is-pc {
                        width: 2.4rem;
                    }
                }
            }
        }

        &-img {
            transition: transform .2s ease-in;
            padding: .5rem;
            box-sizing: content-box;

            &:hover {
                transform: translateY(-.3rem);
            }
        }
    }
}