@mixin is-pc {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin is-pc {
    @media (min-width: 1025px) {
        @content;
    }
}


@mixin button {
    font-family: inherit;
    cursor: pointer;
    font-size: 1.16rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    height: 3.3rem;
    transition: all 0.2s ease-in-out;
    border: none;

    img {
        width: 1.3rem;
        height: auto;
        margin: 0 .7rem;
    }
}

@mixin input {
    font-size: .95rem;
    font-family: inherit;
    outline: none;
    background: #FFFFFF;
    border: 1px solid #DBDADA;
    border-radius: 6px;
    transition: box-shadow .15s ease-in;

    &::placeholder {
        color: #898989;
    }

    &:focus {
        box-shadow: 0px 3px 5px 0px #4d4d4d21;
    }
}