@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes alert-fade-in {
	0% {
		opacity: 0;
		transform: translate(-50%, -12rem);
	}

	100% {
		opacity: 1;
		transform: translate(-50%, 0);
	}
}

@keyframes alert-fade-out {
	0% {
        opacity: 1;
		transform: translate(-50%, 0);
	}

	100% {
		opacity: 0;
		transform: translate(-50%, -12rem);
	}
}