@import 'mixins.scss';
@import 'vars.scss';

.few-steps {
    width: 100%;
    padding: 0 4px;
    display: flex;
    flex-direction: column-reverse;
    margin: 30px 0;

    @include is-pc {
        width: 86%;
        flex-direction: row;
        margin: 0 auto;
        padding: 0;
    }

    &__header {
        font-size: 36px;
        font-weight: normal;
        color: $navy-blue-text;
        text-align: center;

        @include is-pc {
            font-size: 7.57rem;
            line-height: 7.6rem;
            margin-top: 15%;
            white-space: nowrap;
            text-align: right;
        }

        br {
            display: none;

            @include is-pc {
                display: block;
            }
        }
    }

    &__subheader {
        font-size: 20px;
        color: $green-text;
        margin: 5px 0 16px;
        text-align: center;

        @include is-pc {
            font-size: 2.91rem;
            text-align: right;
            margin: 2.5rem 0 5.2rem;
        }
    }

    &__left {
        position: relative;

        a {
            text-decoration: none;
        }

        @include is-pc {
            margin-bottom: 15rem;
        }

        &-big-img {
            width: 100%;
            height: auto;

            @include is-pc {
                max-height: 75vh;
                min-height: 500px;
            }
        }

        &-small-img {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            width: 84%;
            margin: -25% auto 0;

            @include is-pc {
                position: absolute;
                left: 54.5%;
                bottom: 0;
                transform: translateY(40%);
                width: auto;
                max-width: 84%;
                margin: 0;
            }
        }
    }

    &__right {
        @include is-pc {
            z-index: 1;
            flex: 1;
            margin-left: 5%;
        }

        a {
            text-decoration: none;
        }
    }

    &__button {
        @include button;
        background-color: #FFF;
        border: 1px solid #CED2D9;
        font-size: 15px;
        font-weight: 600;
        color: $navy-blue-text;
        line-height: 1;
        height: 44px;
        border-radius: 11px;
        padding: 10px 28px;
        margin: 6px auto 0;

        @include is-pc {
            font-size: 1.57rem;
            height: 4.3rem;
            border-radius: 16px;
            padding: 1rem 2rem;
            margin: 0 0 0 auto;
        }

        &:hover {
            background-color: $navy-blue-text;
            color: #FFF;
        }

        &.on-mobile {
            @include is-pc {
                display: none;
            }
        }

        &.on-pc {
            display: none;

            @include is-pc {
                display: block;
            }
        }
    }
}

[lang="ar"] {
    .few-steps__right {
        @include is-pc {
            margin-right: 5%;
        }
    }

    .few-steps__left-small-img {
        @include is-pc {
            right: 54.5%;
        }
    }
}
