@import 'home.scss';
@import 'takeCare.scss';
@import 'fewSteps.scss';
@import 'testimonials.scss';
@import 'startBuilding.scss';
@import 'reconstructing.scss';
@import 'contact.scss';
@import 'howItWorks.scss';
@import 'aboutUs.scss';
@import 'footer.scss';
@import 'keyframes.scss';
@import 'mixins.scss';
@import 'vars.scss';

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    line-height: 1;
}

html {
    box-sizing: border-box;
    font-family: 'Axiforma', Verdana, sans-serif;
    font-size: 11px;
    scroll-behavior: smooth;
    color: #333333;

    @media screen and (min-width: 1024px) {
        font-size: 12px;
    }

    @media screen and (min-width: 1440px) {
        font-size: 12px;
    }

    @media screen and (min-width: 1600px) {
        font-size: 13px;
    }

    @media screen and (min-width: 1920px) {
        font-size: 14px;
    }

    &[lang="ar"] {
        .key {
            direction: rtl;
        }

        .img-key {
            direction: rtl;
            transform: scale(-1, 1);
        }
    }
}


*, *:before, *:after {
    box-sizing: inherit;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

:focus {
    outline: 0;
}

::-webkit-scrollbar {
    width: .79rem;
}

::-webkit-scrollbar-thumb {
    background-color: #EDEDED;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #D6D6D6;
}

.checkbox-radio {
    position: relative;
    display: flex;
    width: var(--width-checkbox-radio);
    color: black;
    font-size: .94rem;
    font-weight: 500;
    margin: 0 0 1rem 0;

    & input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
    }

    & .checkbox-radio-option {
        display: flex;
        cursor: pointer;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        margin-right: 3rem;

        .checkbox-radio-input {
            content: "";
            width: 43px;
            height: 43px;
            margin: 0 15px 0 0;
            background-color: #F1F9FF;
            border: 5px solid #F1F9FF;
            box-shadow: 0px 0px 0px 1px $input-border-color;
            border-radius: 14px;
            background-clip: padding-box;
            transition: ease-in-out 0.2s;

            @include is-pc {
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                margin: 0 .7rem 0 0;
                border: 2px solid white;
            }
        }

        &:hover .checkbox-radio-input {
            box-shadow: 0px 0px 0px 1px $navy-blue-text;
            background-color: $navy-blue-text;
        }

        & input:checked ~ .checkbox-radio-input {
            box-shadow: 0px 0px 0px 1px $navy-blue-text;
            background-color: $navy-blue-text;
        }
    }
}

.checkbox-container {
    position: relative;
    display: flex;
    font-size: .94rem;
    font-weight: 500;

    input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
    }

    .checkbox-container-option {
        display: flex;
        cursor: pointer;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        .checkbox-container-input {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 .66rem 0 0;
            height: 2.79rem;
            width: 2.79rem;
            border-radius: .86rem;
            background-color: #f1f9ff;
            border: none;
            transition: background-color .1s ease-in;

            &:hover {
                background-color: rgb(212, 212, 212);
            }
        }

        input:checked ~ .checkbox-container-input {
            position: relative;
            background-color: $navy-blue-text;
            border: 1px solid #FFF;
        }

        .checkbox-container-input:after {
            content: "";
            display: none;
            width: .7rem;
            height: 1.4rem;
            margin-bottom: .5rem;
            border: solid #fff;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }

        input:checked ~ .checkbox-container-input:after {
            display: block;
        }
    }
}

.landing-page {
    animation: fade-in .2s ease;
    overflow: auto;
    height: 100%;
    min-height: 100vh;

    @include is-pc {
        max-width: 2560px;
        margin: 0 auto;
    }
}

.popup {
    min-width: 90%;
    max-width: 90%;
    width: auto;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 6px 16px 2px rgb(0 0 0 / 12%);
    position: fixed;
    padding: 20px;
    top: 1rem;
    left: 50%;
    z-index: 111;
    animation: alert-fade-in .5s;
    transform: translate(-50%, 0);
    font-size: 13px;

    @include is-pc {
        min-width: 45.4rem;
        max-width: 60vw;
        font-size: 1rem;
        padding: 1.5rem;
    }

    &[data-type="success"] {
        border-left: 5px solid #05D94E;
        background-color: rgb(218 250 229);
    }

    &[data-type="error"] {
        border-left: 5px solid #ec5469;
        background-color: #f5d8dc;
    }
}

[data-role="popups"] {
    > div[data-is-active="false"] {
        .popup {
            animation: alert-fade-out .5s;
            opacity: 0;
            transform: translate(-50%, -12rem);
        }
    }
}

.mobile-menu {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    z-index: 50;
    background-color: #FFF;
    position: fixed;
    top: 61px;
    left: 0;
    right: 0;
    bottom: 0;
    animation: fade-in .2s ease-in-out;
    display: none;
    flex-direction: column;
    opacity: 0;

    &__container {
        overflow: auto;
        padding-bottom: 91px;
    }

    &__bg {
        z-index: -1;
        position: absolute;
        top: -37px;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    &[data-is-active="true"] {
        display: flex;
        opacity: 1;
    }

    &__nav {
        width: 100%;
        overflow: auto;
        padding: 21px 20px 0;

        &-list {
            text-align: right;
            list-style-type: none;
        }

        &-item {
            margin: 15px 0;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(#213787, .1);


            a {
                font-size: 20px;
                font-weight: 600;
                color: $navy-blue-text;
                text-decoration: none;
            }

            &.subheader {
                border-bottom: none;
                padding-bottom: 0;
            }

            &.subitem {
                border-bottom: none;
                padding-bottom: 0;
                margin: 16px 0;

                a {
                    font-weight: 400;
                }
            }

            &.about-us, &.contact {
                padding-top: 10px;
            }
        }
    }

    .contact {
        &-text {
            font-size: 36px;
            color: $navy-blue-text;
            text-align: center;
            margin: 41px 0 23px;
        }

        &-row {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        &-tel {
            display: flex;
            align-items: center;
            margin: 0 0 10px;

            img {
                width: 29px;
                height: 29px;
                margin: 0 8px;
            }

            a {
                font-size: 20px;
                color: $navy-blue-text;
                text-decoration: none;
                transition: color .2s ease;

                &:hover {
                    color: $green-text;
                }

                &:visited {
                    color: $navy-blue-text;
                }
            }
        }

        &-email {
            display: flex;
            align-items: center;

            img {
                width: 29px;
                height: 29px;
                margin: 0 8px;
            }

            a {
                font-size: 20px;
                color: $navy-blue-text;
                text-decoration: none;
                transition: color .2s ease;

                &:hover {
                    color: $green-text;
                }

                &:visited {
                    color: $navy-blue-text;
                }
            }
        }
    }
}

.mobile-items {
    display: flex;
    align-items: center;

    @include is-pc {
        display: none;
    }

    .lang-menu {
        .earth {
            width: 18px;
            height: 18px;
        }

        .lang {
            font-size: 15px;
            color: $navy-blue-text;
        }
    }

    .open {
        width: 28px;
        height: 17px;
        margin: 4px 2px 0 0;
        cursor: pointer;
    }
}

.on-mobile {
    @include is-pc {
        display: none;
    }
}

.on-pc {
    display: none;

    @include is-pc {
        display: inherit;
    }
}
